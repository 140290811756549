import React, { useState } from "react";
import "./style.scss";
import Layout from "../layout";
import Header from "../header";
import data from "./team.data";
import Seo from "../seo";
import linkedInIcon from "../../images/icons/icons8-linkedin-2-30.png";
import ProfileModal from "../profilemodal";
import { Link } from "gatsby";

export default function Team() {
  const [showModal, setShowModal] = useState(false);
  const [currentMember, setCurrentMember] = useState({});

  function toggleModal() {
    setShowModal(!showModal);
  }

  function displayModal(member) {
    if (member.bio.length > 0) {
      setCurrentMember(member);
      toggleModal();
    }
  }

  const teamMembersJSX = data.map((member, i) => (
    <div className="col col-6 col-md-4 col-lg-3">
      <div
        className="team__team-wrapper__member"
        onClick={() => {
          displayModal(member);
        }}
      >
        <div className="team__team-wrapper__member__image-container">
          <img
            className="team__team-wrapper__member__image"
            src={member.image}
            alt={member.alt}
          ></img>

          <h6 className="team__team-wrapper__member__name">
            {member.name}{" "}
            {member.linkedInProfile.length > 0 && (
              <a href={member.linkedInProfile} target="__blank">
                <img
                  className="team__team-wrapper__member__linkedin"
                  alt="LinkedIn"
                  src={linkedInIcon}
                ></img>
              </a>
            )}
          </h6>
          <div className="team__team-wrapper__member__designation">
            {member.designation}
          </div>
        </div>
      </div>
    </div>
  ));
  return (
    <>
      <Layout>
        <Seo
          title="A&D Studio | Meet The Team"
          description="Our team consists of some of the top urban design professionals in the country, with wide experience in institutional practices, commercial and residential work. "
          img="https://archdesignsstudio.com/assets/linkpreview.png"
        />
        <Header isWhite />
        <div className="team">
          <Link className="team__back" to={"/projects"}>
            &#8592;
          </Link>
          <div className="row team__row">
            <div className="col col-12 col-lg-5">
              <div className="team__leftcontainer">
                <h1
                  className="heading-primary"
                  style={{ textAlign: "center", padding: "0", marginBottom: 0 }}
                >
                  Our Team
                </h1>
                <p className="team__description">
                  A & D Studio is regulated with a team of energetic leaders
                  from well-established education backgrounds that showcase a
                  multitude of talent and genuine creativity. It is true
                  professionalism that drives them to communicate, maintain
                  strict schedules and co-ordinate all relevant information and
                  maintain healthy relationships with Clients, Consultants and
                  Contractors.
                  <br />
                  <br /> Their sense of camaraderie and team leadership skills
                  strengthens bonds within the studio and ensures high quality
                  of work output. Along with precision, a great attention to
                  detail and valuable market knowledge makes certain a holistic
                  outlook. It is this ability to take on and distribute
                  responsibility that facilities A & D Studio to perform as one
                  single coherent unit.
                </p>
              </div>
            </div>
            {/* <div className="row team__team-wrapper">{teamMembersJSX}</div> */}
            <div className="col col-12 col-lg-7">
              <div className="team__rightcontainer">
                <div className="row team__team-wrapper">{teamMembersJSX}</div>
              </div>
            </div>
          </div>

          {/* <div className="team__divider"></div> */}
        </div>
      </Layout>
      <ProfileModal
        name={currentMember.name}
        designation={currentMember.designation}
        bio={currentMember.bio}
        onClickClose={toggleModal}
        show={showModal}
      />
    </>
  );
}
