import React from "react";
import "./style.scss";
function ProfileModal({ name, designation, bio, show, onClickClose }) {
  return (
    <div className={`profile-modal ${show && "profile-modal--show"}`}>
      <button className="profile-modal__close" onClick={onClickClose}>
        CLOSE
      </button>
      <h5 className="profile-modal__name">{name}</h5>
      <div className="profile-modal__designation">{designation}</div>
      <div className="profile-modal__divider"></div>
      <p
        className="profile-modal__bio"
        dangerouslySetInnerHTML={{ __html: bio }}
      ></p>
    </div>
  );
}
ProfileModal.defaultProps = {
  show: false,
  onClickClose: () => {},
};

export default ProfileModal;
